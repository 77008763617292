<template>
<section class="container h-100">
    <div class="row h-100 align-items-center">
        <div class="col-12 col-lg-6 text-white">
            <h1 class="text-left font-weight-light mt-5 mt-lg-0">
                Acesse a nossa plataforma e construa um <b class="font-weight-bold">atendimento personalizado!</b>
            </h1>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card my-5">
                <div class="card-body p-5 p-md-5">
                    <section v-if="!sended">
                        <h4 class="title-border mb-4 text-left">Esqueceu sua senha?</h4>
    
                        <validation-observer ref="forgotPasswordValidationForm" tag="form">
                            <div class="row py-2">
                                <div class="col-12 my-2">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="email"
                                        rules="required|email"
                                    >
                                        <input 
                                            type="email" 
                                            placeholder="E-mail"
                                            class="form-control"
                                            :class="{ 'is-invalid': errors.length > 0 }"
                                            :readonly="loading"
                                            v-model="form.email"
                                        />
                                    </validation-provider>
                                </div>
                            </div>
                        </validation-observer>
    
                        <div class="row align-items-center secondary-colors">
                            <div class="col-12 col-lg-auto">
                                <button :disabled="loading" @click="submit" class="btn btn-primary px-5 mr-lg-4 mt-3 w-100 w-lg-auto">
                                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status"></span>
                                    <span v-else>Recuperar</span>
                                </button>
                            </div>
                            <div class="col-12 col-lg-auto pt-3">
                                <router-link custom :to="{ name: 'auth-login' }" v-slot="{ href, navigate }">
                                    <a :href="href" @click="navigate" class="link-primary font-weight-bold">
                                        Voltar
                                    </a>
                                </router-link>
                            </div>
                        </div>
                    </section>
                    
                    <div v-else class="secondary-colors">
                        <font-awesome-icon icon="fa-solid fa-envelope" style="width: 3rem; height: 3rem;"/>
                        <h3 class="mt-2 text-dark">Email de redefinição enviado</h3>
                        <p class="text-dark">Um email com um link de redefinição de senha foi enviado para o email informado. Verifique a caixa de spam caso não esteja encontrando.</p>
                        <router-link custom :to="{ name: 'auth-login' }" v-slot="{ href, navigate }">
                            <a :href="href" @click="navigate" class="link-primary font-weight-bold">
                                Voltar
                            </a>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import api from "@/api";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            required,
            email,
            loading: false,
            form: {
                email: null,
                redirect: null
            },
            sended: false
        }
    },
    created() {
        const base = window.location.origin;
        const path = this.$router.resolve({ name: 'auth-reset-password' }).href;
        this.form.redirect = `${base}${path}`;
    },
    methods: {
        toast(message, type = 'danger') {
            this.$bvToast.toast(message, {
                title: 'Esqueci minha senha',
                variant: type
            });
        },
        async submit() {
            const validated = await this.$refs.forgotPasswordValidationForm.validate();

            if(!validated) return;

            this.loading = true;
            api.post('/users/forgot-password', this.form)
            .then(response => {
                const { type, message } = response.data;

                if (type == 'success') {
                    return this.sended = true;
                }
                
                this.toast(message)
            })
            .catch(error => {
                var message = 'Não foi possível processar sua requisição corretamente. Tente novamente mais tarde.';

                if (error.response) {
                    const { errors } = error.response.data;
                    message = errors.length > 0 ? errors[0] : error.response.data.message;
                }
                return this.toast(message)
            })
            .finally(() => this.loading = false)
        }
    },
}
</script>